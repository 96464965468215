<template>
  <keep-alive :include="include">
    <router-view />
  </keep-alive>
</template>

<script>
import { mapState } from "vuex";
import aeepAlive from "@/router/aeepAlive";

export default {
  name: "ConstructionMachinery",
  computed: {
    // ...mapState(["cachePages"]),
    /**
     * 需要缓存的页面名称列表
     */
    include() {
      // return this.cachePages.map((menu) => menu.name);
      return aeepAlive;
    },
  },
};
</script>
